@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.footer {
	position: static;
	bottom: 0;
	width: 100%;
	padding: vars.$spacing-xx-small;
	background-color: vars.$color-light-black;

	&__logo {
		width: vars.$spacing-large-increased;
		height: vars.$spacing-small-xx;
	}
}
