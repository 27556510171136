@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.login {
	@include mix.flexArea(column, center, center);
	padding: vars.$spacing-xxx-small vars.$spacing-atomic-increased;
	box-shadow: 0 vars.$spacing-xxx-atomic vars.$spacing-atomic 0 rgb(16 24 32 / 10%);
	max-width: vars.$dimension-large-extra;

	&__wrapper {
		@include mix.flexArea(row, center, center);
		padding: vars.$spacing-atomic-increased;
		min-height: calc(100vh - vars.$spacing-atomic);
	}

	&__header {
		.header__logo {
			width: vars.$dimension-small-x;
			height: vars.$spacing-xxx-small;
		}
	}

	&__form {
		@include mix.flexArea(column, center, center);
		margin: vars.$spacing-micro-xx;
		width: 100%;

		&__input {
			@include mix.borderedArea(2px, vars.$color-input-border, vars.$spacing-x-atomic);
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-input-fg-2,
				vars.$text-size-micro,
				vars.$text-weight-light,
				vars.$text-size-micro-x
			);
			width: 100%;
			height: vars.$spacing-micro;
			background-color: vars.$color-white;
			padding: vars.$spacing-xxx-atomic vars.$spacing-atomic;
			margin-bottom: vars.$spacing-atomic;
		}
		&__input:focus {
			border-color: vars.$color-input-border;
		}

		&__feedback {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-secondary,
				vars.$text-size-atomic-x,
				vars.$text-weight-light,
				vars.$text-size-atomic-x
			);
			margin-bottom: vars.$spacing-atomic-xxx;
		}

		&__submit {
			@include mix.button(
				vars.$spacing-large,
				vars.$spacing-x-atomic,
				vars.$color-primary,
				vars.$color-primary-light,
				vars.$color-white,
				vars.$color-white,
				vars.$spacing-atomic,
				vars.$text-size-micro,
				vars.$text-weight-light
			);
		}
		&__submit:disabled {
			background-color: vars.$color-input-border;
			cursor: not-allowed;
		}
	}

	&__footer {
		@include mix.flexArea(column, center, center);
		width: 100%;

		&__link {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-primary,
				vars.$text-size-micro,
				vars.$text-weight-light,
				vars.$text-size-micro
			);
			margin-bottom: vars.$spacing-atomic;
		}
	}
}
