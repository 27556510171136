@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.banner__section {
	padding: 0 vars.$spacing-micro;
	margin-bottom: vars.$spacing-medium-x;

	&__content {
		@include mix.flexArea(column, center, center);

		.content__title {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-large,
				vars.$text-weight-bold,
				initial
			);
			margin-bottom: vars.$spacing-atomic-xx;
		}
		.content__description {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-micro-x,
				vars.$text-weight-light,
				vars.$spacing-atomic-xx
			);
			margin-bottom: vars.$spacing-atomic-xx;
		}
		.content__requestdemo {
			@include mix.flexArea(row, center, flex-start);
			width: 100%;
			margin-top: vars.$spacing-atomic-increased;

			.requestdemo__email {
				background-color: vars.$color-input-bg;
				color: vars.$color-input-fg;
				max-width: 50%;
				border-radius: vars.$spacing-atomic;
				margin-right: vars.$spacing-xx-atomic;
				height: vars.$spacing-micro;
				border-color: transparent;
			}
			.requestdemo__email:focus {
				box-shadow: none;
				border-color: transparent;
			}

			.requestdemo__submit {
				@include mix.button(
					vars.$spacing-large,
					vars.$spacing-x-atomic,
					vars.$color-primary,
					vars.$color-primary-light,
					vars.$color-white,
					vars.$color-white,
					vars.$spacing-atomic,
					vars.$text-size-micro,
					vars.$text-weight-light
				);
			}
		}
	}

	.banner_section_preview {
		width: 720px;
		height: 550px;
	}
	
}

.services__section {
	margin-bottom: vars.$spacing-medium-x;

	&__title {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-large-x,
			vars.$text-weight-bold,
			initial
		);
		margin-bottom: vars.$spacing-atomic-xx;
	}

	&__content {
		@include mix.flexArea(row, center, center);

		.content__preview {
			width: 555px;
			height: 550px;
			margin-right: vars.$spacing-atomic;
		}

		.content__details {
			margin-left: vars.$spacing-atomic;

			.details__service {
				margin-bottom: vars.$spacing-micro-xx;

				.service__name {
					@include mix.text(
						vars.$text-family-urw,
						vars.$color-light-black,
						vars.$text-size-small,
						vars.$text-weight-bold,
						vars.$text-size-small
					);
					margin-bottom: vars.$spacing-atomic;
				}

				.service__description {
					@include mix.text(
						vars.$text-family-urw,
						vars.$color-light-black,
						vars.$text-size-micro-x,
						vars.$text-weight-light,
						vars.$text-size-x-small
					);
				}
			}
		}
	}
}

.empowering__section {
	margin-bottom: vars.$spacing-xx-large;

	&__wrapper {
		@include mix.flexArea(row, start, start);
		padding: vars.$spacing-atomic-increased;
		background-color: vars.$color-special-section;
		border-radius: vars.$spacing-atomic;
	}

	&__heading {
		.empowering__section__title {
			@include mix.text(vars.$text-family-urw, vars.$color-light-black, vars.$text-size-x-large, 500, initial);
		}

		.empowering__section__description {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-micro-x,
				vars.$text-weight-light,
				initial
			);
		}
	}

	.empowering__list {
		padding-left: vars.$spacing-medium-increased;
		column-count: 2;
		width: 100%;

		&__item {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-x-small,
				vars.$text-weight-light,
				vars.$text-size-small
			);
			margin-bottom: vars.$spacing-atomic;
		}
	}
}

.products__section {
	margin-bottom: vars.$spacing-xx-large;

	.products__wrapper {
		@include mix.flexArea(row, start, space-between);
		padding-right: vars.$spacing-xx-micro;
	}

	.products__section__title {
		@include mix.text(
			vars.$text-family-fira,
			vars.$color-light-black,
			vars.$text-size-x-large,
			500,
			vars.$spacing-atomic-xx
		);
	}

	.products__section__description {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-micro-x,
			vars.$text-weight-light,
			vars.$spacing-atomic-xx
		);
	}
}

.bridging__section {
	margin-bottom: vars.$spacing-xx-large;

	&__content {
		margin-right: vars.$spacing-micro;

		.content__title {
			@include mix.text(
				vars.$text-family-fira,
				vars.$color-light-black,
				vars.$text-size-large,
				500,
				vars.$spacing-atomic-xx
			);
			margin-bottom: vars.$spacing-atomic-xx;
		}
		.content__description {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-micro-x,
				vars.$text-weight-light,
				vars.$spacing-atomic-xx
			);
		}
	}

	&__preview {
		width: 480px;
		height: 345px;
	}
}

.partenrs__section {
	margin-bottom: vars.$spacing-xx-large;

	&__title {
		@include mix.text(
			vars.$text-family-fira,
			vars.$color-light-black,
			vars.$text-size-large,
			500,
			vars.$spacing-atomic-xx
		);
		margin-bottom: vars.$spacing-x-atomic;
	}

	&__description {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-micro-x,
			vars.$text-weight-light,
			vars.$spacing-atomic-xx
		);
		margin-bottom: vars.$spacing-atomic-xxx;
	}

	&__content {
		@include mix.flexArea(row, center, space-evenly);
		flex-wrap: wrap;

		.content__partener {
			@include mix.flexArea(row, center, center);
			margin-bottom: vars.$spacing-micro-xx;
			flex: 0 0 27%;

			.partener__img {
				width: 35%;
				height: auto;
				object-fit: scale-down;
			}
		}
	}

	&__readmore {
		@include mix.text(
			vars.$text-family-urw,
			initial,
			vars.$text-size-micro,
			vars.$text-weight-light,
			vars.$text-size-small-x
		);
		@include mix.button(
			vars.$spacing-large-xxx,
			vars.$spacing-xx-atomic,
			vars.$color-secondary,
			vars.$color-secondary-light,
			vars.$color-white,
			vars.$color-neon-blue,
			vars.$spacing-atomic,
			vars.$text-size-micro,
			vars.$text-weight-light
		);
	}
}
