@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.paymentbalance {
	@include mix.flexArea(column, center, center);
	margin-left: vars.$spacing-micro;
	margin-right: vars.$spacing-micro;
	margin-top: vars.$spacing-atomic;
	margin-bottom: vars.$spacing-large-increased;

	&__title {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-large-xx,
			vars.$text-weight-bold,
			initial
		);
		margin-top: vars.$spacing-atomic;
	}

	&__subtitle {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-medium-x,
			vars.$text-weight-light,
			initial
		);
		margin-top: vars.$spacing-atomic;
	}

	&__details {
		margin-top: vars.$spacing-micro;

		.paymentbalance__balance {
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-small,
				vars.$text-weight-light,
				initial
			);
		}
	}

	&__link {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-primary,
			vars.$text-size-large,
			vars.$text-weight-light,
			vars.$text-size-micro
		);
		margin-top: vars.$spacing-atomic;
	}
}
