
@import url("//fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,800,700,900");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway';

}
:root {
  font-family: 'Raleway' !important;
}
.pageNum-input::-webkit-inner-spin-button {
  opacity: 1;
}

.rs-picker-toolbar-right button {
  background-color: rgb(37 99 235) !important;
}

.rs-picker-toolbar-right button:hover {
  background-color: rgb(6, 71, 211) !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .pink-shadow {
    box-shadow: 3.994px 22.651px 57px rgba(249, 103, 103, 0.259);
  }

  .table-row-space {
    border-spacing: 0rem 1rem;
  }


  
  .input1 {
    width: 100%;
    border-radius: 15px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    padding: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(122 134 161 / var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    height: 55px;

    /* left: 1076px; */
    /* top: 491px; */
    border-radius: 20px;
}

  .input-feedback {
    @apply text-xs text-red-500;
  }

  .card1 {
    @apply p-3 border-[1px] border-zinc-400 flex flex-row justify-start items-center rounded-md w-[225px] m-2 cursor-pointer;
  }

  .gradient-btn {
    @apply py-2 px-8 rounded-[15px] text-center bg-gradient-to-r from-[#A92D62] to-[#A92D62]  font-[700] text-white outline-none text-[20px] ;
  }

  label {
    display: inline-block;
    margin-left: 0px !important;
 
    margin: 7px 7px 7px 0px !important;
}

.input {
  width: 100%;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    padding: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 100;
    --tw-text-opacity: 1;
    color: rgb(122 134 161 / var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
    font-size: 14px;
    font-family: 'Raleway';
    line-height: 21px;
    letter-spacing: -0.015em;
    /* color: unset; */

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 165%;
    display: flex;
    align-items: center;
    color: #000;
}
  .outline-dark{
    outline: 2px solid #8ED1C6;
    border-radius: 15px 15px 15px 15px;
  }.card1 {
    margin: 1.5rem !important;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

   
    padding: 2.75rem !important;
    filter: drop-shadow(8px 8px 17px #E8EFFE);
    box-sizing: border-box;
    width: 362px !important;
    height: 140px !important;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
}

.monthly-yr{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 17px 36px; */
  gap: 10px;
  /* position: absolute; */
  width: 122px;
  height: 60px;
  /* left: 656px; */
  top: 338px;
  background: #A92D62;
  border-radius: 50px 0px 0px 50px;
  }
  
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 4px solid #EBEBEB;
}
/* Basic styles for nav links */
.nav a {
  display: flex;
  
 
  text-decoration: none;
  /* letter-spacing: -0.015em; */
  font-size: 0.875rem;
  line-height: 1.25rem;
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */
 
  font-weight: 700 !important;
  color: #01BABF;
}

/* Specific styles for non-active links */
.link {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin-right: 0.8rem!important;
    margin-left: 0.8rem!important;
  color: #048A8F;
  
}



.link :hover {
 
  color: #fff !important;
 
 

}
/* Specific styles for active links */
.link-active {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin-right: 0.8rem!important;
  margin-left: 0.8rem!important;
  color: #01BABF;
 
}
.py-20 {
  padding-top: 6rem !important;
  padding-bottom: 5rem !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 0.5rem; */
  /* padding-bottom: 0.5rem; */
}
#inputID::placeholder {
  color: #FFF;
  opacity: 1;
}
}
.image-upload>input {
  display: none;
}



.form-check-input {
  width: 2em;
  height: 2em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1pxsolidrgba(0,0,0,.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}


label {
  display: inline-block;
  margin-left: 10px;
  margin-top: 8px;
}

  .form-check-input:checked {
    background-color: #048A8F;
    border-color: #048A8F;
}


.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
  border-color: rgba(0,0,0,.1);
  margin-left: -10px;

} 


.translation{


filter: drop-shadow(0px 10px 30px rgba(104, 127, 128, 0.25));
box-shadow: 0px 4px 7px rgb(20 66 68 / 15%);
border-radius: 32px 32px 32px 32px;
height: 651px;

}


.title{
  font-family: 'Raleway' !important;
font-style: normal;
font-weight: 700;
font-size: 34px;
line-height: 40px;

color: #01BABF;

}
.text-sm {

 

 
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  
}

.text-\[\#363636\] {
  --tw-text-opacity: 1;
  color: rgb(54 54 54 / var(--tw-text-opacity));
  font-family: 'Raleway';
  font-weight: 500;
}



.a{
  font-size: 14px;
  font-family: 'Raleway';
  line-height: 21px;
  letter-spacing: -0.015em;
}

.right-3 {
  right: 2.75rem;
}
.bottom-3 {
  bottom: 2.75rem;
}



.top-\[3\%\] {
  top: 0%;
}

.right-\[47\%\] {
  right: 42%;
}


.link {

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin-right: 0.8rem!important;
  margin-left: 0.8rem!important;
  color: #048A8F;
}




.loggeduser{
 
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 42px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
   
  


}
.button {

font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 21px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.015em;
margin-right: 0.8rem!important;
margin-left: 0.8rem!important;
color: #000;
}

a {
  color: none;
  text-decoration: inherit;
}

.btn-primary:hover {background-color: #fff;
color:#fff}
.link :hover {
 
  color: #fff !important;
 
 

}
/* Specific styles for active links */
.link-active {
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-right: 0.8rem!important;
    margin-left: 0.8rem!important;
    color: #01BABF;
 
}


.en {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
 
  letter-spacing: -0.015em;
  margin-right: 0!important;
    margin-left: 0rem!important;
  

  
  color: #048A8F;
 
}



  .wrapper {
    border: 1px solid #e3dfdf;

}
.wrapper textarea{
  background: #FCFCFC ;
  border:none;
  width:100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px dotted #e3dfdf;
  resize: none;
}
.wrapper textarea:focus{
  outline: none;
}
.controls{
  text-align: right;
  margin-top: -6px;
}
.controlsdownload{
  text-align: right;
  margin-top: -44px;
  margin-right: 65px;
}


  .controlscopy {
    text-align: right;
    margin-top: 20px;
    margin-right: 0px;

}
.controlsmic {
     /* text-align: left; */
     margin-top: -6px;
  

}

.textcount{
  text-align: right;
  margin-right: 7px;
  margin-left: 242px;
}


.controlsshare{
  text-align: right;
    margin-top: -43px;
}

.controls1{
  text-align: left;
  margin-top: -6px;
}


.controls2{
  text-align: left;
    margin-top: -28px;
    margin-left: 39px;
}


.controls3 {
    text-align: left;
    margin-top: -28px;
    margin-left: 202px;
    border-radius: 25px 25px 25px 25px;
    border: 0px;
}


.controlstranslate{

  text-align: center;
  margin-top: -26px;
  /* margin-right: -207px; */
  margin-left: 154px;
  margin-left: 110px;

}
.controlstranslatebtn{
  text-align: right;
  margin-right: 2px;
  margin-top: -38px;
}
.controlsclear{
  text-align: left;
  margin-top: 6px;
  margin-left: 1px;
}

.controlstext{
  position: relative;
  left: 3%;

  /* right: 50%; */
  transform: translate(-50%,-50%);
  letter-spacing: normal;
  /* direction: ltr; */
  /* position: absolute; */
  /* right: 40px; */
  top: -250px;
  /* width: 31px; */
  /* height: 32px; */
  float: right;



}


.controlstext1{
  position: relative;
  left: 3%;

  /* right: 50%; */
  transform: translate(-50%,-50%);
  letter-spacing: normal;
  /* direction: ltr; */
  /* position: absolute; */
  /* right: 40px; */
 /*  top: -250px; */
  top: -166px;
  /* width: 31px; */
  /* height: 32px; */
  float: right;



}


.ppaudio{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  text-align: center;
  
  color: #048A8F;
}

.home{
  font-family: 'Raleway';
font-size: 49px;
font-weight: 600;
line-height: 70px;
letter-spacing: 0em;
text-align: center;

color:  #048A8F;
;

}

.lang9{
  font-family: 'Raleway';
font-size: 25px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0em;
text-align: center;
}
.pricingHeading{

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 80px;
  /* or 154% */
  
  text-align: center;
  
  color: #363636;
}

.contactHeading{

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 80px;
  /* or 154% */
  
  text-align: center;
  
  color: #363636;
  
}

.contacttitle{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;
/* identical to box height, or 104% */

text-align: center;

color: #A92D62;
}


.pricingtitle{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;
/* identical to box height, or 104% */

text-align: center;

color: #A92D62;

}
.shadow-md{
  background: #FFFFFF;
  border: 5px solid #EBEBEB;
  border-radius: 20px;
}

.pricinglabel{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 28px;
/* or 140% */


color: #048A8F;

opacity: 0.8;
}
.pricingper{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
/* or 156% */


color: #000000;

opacity: 0.7;
}
.dollar{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 50px;
line-height: 42px;
/* or 84% */

letter-spacing: 1px;

color: #01BABF;

}
.wordscontent{

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  
  
  color: #000000;
}
.pricingbtn{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 17px 36px;
gap: 10px;

left: 9.81%;
right: 79.31%;
top: 48.44%;
bottom: 48.34%;
font-weight: 700;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */

text-align: center;

color: #048A8F;

background: #FFFFFF;
border: 2px solid #048A8F;
border-radius: 20px;
}
.pricinghr{
  opacity: 0.1;
  /* color 6 */
  
  border: 1px solid #0D59EF;
}
.solutiontitle{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;
/* identical to box height, or 104% */

text-align: center;

color: #A92D62;

}
.subHeading2{
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  /* or 227% */
  
  
  color: #363636;

text-align: left;
}

.txtmodal{

}

.subHeading1{
  font-family: 'Raleway';
font-size: 40px;
font-weight: 600;
line-height: 60px;

text-align: left;
}
.lang9sub {
  font-size: 14px;
  font-family: 'Raleway';
  line-height: 21px;
  letter-spacing: -0.015em;
  text-align: center;
}

.bridge{
  font-family: 'Raleway';
font-size: 30px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0em;

}

.select{
  font-family: 'Raleway' !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  height: 62.89855194091797px;
  width: 209.68060302734375px;
  /* left: 529.0009765625px; */
  /* top: 676.062255859375px; */
  border-radius: 30px;

  color: #363636;
}


.btnheadding{
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
/*   padding: 5px 15px 0px 26px; */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
    


}


.btnsubheadding{

  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center !important;
text-align: center !important ;

width: 237.125473px;
    border-radius: 25px;
}


.text-xs {
  font-size: 0.85rem;
  
}
.backSignin{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;

color: #01BABF;
}
.subtitile{
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 188% */


color: #323232;
}

.mx-20 {
  margin-left: 7rem;
  margin-right: 5rem;
}
.pricepermonth{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 38px;
line-height: 60px;
/* identical to box height, or 158% */


color: #048A8F;

}
.pricepermonthdec{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 45px;
  /* or 196% */
  
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  
  color: #363636;
}
.parameters{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 23px;
line-height: 70px;
/* or 304% */

display: flex;
align-items: center;
letter-spacing: -0.015em;

color: #646464;
}
.pricingdec{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 70px;
/* or 280% */
height: 361px;

border-radius: 0px;


align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #363636;
background-image: url(../public/assets/imgs/pricingbk.png);

}


.translationfree{
  display: block;
  margin-left: auto;
  margin-right: auto;
 
/* background-image: url(../public/assets/imgs/translationfree.png); */

}
.guest{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 38px;
line-height: 45px;

align-items: center;
letter-spacing: -0.015em;
text-decoration: underline #01BABF ;
text-underline-position: under;
color: #363636;
}
.flexitemhead{
  background-color:  #ACD7D980;
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 23px;
line-height: 27px;
display: flex;
height: 36px;
align-items: center;
letter-spacing: -0.015em;

color: #363636;
  
}
.flexitemDes{
  background-color: #F7FEFE;
  ;
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 27px;
display: flex;
align-items: center;
letter-spacing: -0.015em;

color: #646464;
}
.purchaseNow{
  background: #D9D9D9;
border-radius: 20px;
height: 67px;
width: 236px;

border-radius: 20px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;

align-items: center;
text-align: center;

color: #000000;
}

.follow{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 28px;
/* or 175% */

align-items: center;

color: #048A8F;
}

.flexitem{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 23px;
line-height: 27px;

align-items: center;
letter-spacing: -0.015em;

color: #363636;
}
.gusetdes{
  
font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 27px;

align-items: center;
letter-spacing: -0.015em;

color: #363636;

}

.contactcus{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;

padding-top: 5%;
text-align: center;

color: #A92D62;

}
.getintouch{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 52px;
line-height: 80px;
/* or 154% */

text-align: center;

color: #363636;
}
.contactusbg{
background: #f7f7f7 ;
height: 499px;

}
.contactcusdec{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 40px;
/* or 174% */

align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #363636;
}
@media only screen and (min-width: 600px) {
  .tooltiptext {
    display: none;
  }
}


.CircularProgressbar .CircularProgressbar-path {
  stroke: #048A8F;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #048A8F;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.my-facebook-button-class{
border: 1px solid;
padding: 12px;
background: #4267B2;
color: #fff
}
.my-apple-button-class{
  color: white;
  background: black;
  padding-left: 15px;
  padding-right: 27px;
  padding-top: 11px;
}

.formmask {
 
  margin: -3px -5px -571px -69px;
  height: 525px !important;
  /* width: 810px !important; */
  margin-top: 10px;
}


.mask2 {
  margin: 10px 0px -524px -69px;
  margin-top: 11px;
  position: absolute;
  z-index: -8;
}
.contactlabel{
  margin-top: 3.5rem!important;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 165%;
  /* identical to box height, or 33px */
  
  display: flex;
  align-items: center;
  
  color: #A92D62;

}
.contactdots{
  margin-left: 19%;
  position: absolute;

}
.contacttext{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 38px;
  /* identical to box height, or 160% */
  
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  
  color: #363636;
}

.px-20 {
  padding-left: 3rem;
  padding-right: 4rem;
}

.footerHeading{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */
  
  letter-spacing: -0.015em;

}

.footerhr{
  height: 4px !important;
  color: #EDF1F1;
}

.subHeading2{
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 50px;
    color: #363636;
    padding-right: 93px;
    padding-top: 14px;
}


.aboutHeading{
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;
/* identical to box height, or 104% */


color: #A92D62;
}

/* iframe {
  background: '#C2FCFE';
  border: 61px solid #EEF9FA;
  -moz-border-radius: 15px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  height: 395px !important;
  width: 100%;
} */

.random {
  width: 4.75rem;
    height: 4.75rem;
    text-align: center;
    font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 46px;
line-height: 32px;
/* or 70% */


align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #FFFFFF;
}


.getquote {

  height: 67px;
  width: 225px;

  color: white;
  background: #A92D62;
  font-family: 'Raleway';
  border-radius: 10px;
}

.er {
  margin-left: 40px
  /* margin-right: 16px; */
}
.modaltitle{

  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 33px;
/* identical to box height */

display: flex;
align-items: center;

color: #048A8F;


}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
   border-top: 0px solid #dee2e6 !important; 
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0px solid #dee2e6 !important;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modaltext{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 60px;
  /* or 300% */

  align-items: center;
  
  color: #000000;
  
}
.solutionHeadding{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 49px;
line-height: 80px;
/* or 154% */

text-align: center;

color: #363636;

}


.bringingSolutions{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 80px;
  /* or 154% */
  
  text-align: center;
  
  color: #363636;
}
.solutioncardHeadding{
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 60px;

    color: #048A8F;

}
.newuser{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 50px;
/* or 208% */
padding: 0px 314px 0px 314px;

align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #363636;
}
.subDescription{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */
  
  text-align: center;
  
  color: #676767;
  
  opacity: 0.7;
}

.translationcard{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 26px;
  /* identical to box height, or 104% */
  
  
  color: #363636;
  
}

.supportDescription{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 53px;
  /* identical to box height, or 132% */
  
  text-align: center;
  letter-spacing: -1px;
  
  color: #048A8F;
}

.supportHeading{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */
  
  text-align: center;
  
  color: #A92D62;
  
}

.faqHeadding{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 60px;
/* or 150% */


color: #048A8F;

}

.faq{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;
/* identical to box height, or 104% */


color: #A92D62;
}

.faqquestions{

  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 135% */

letter-spacing: -0.02em;

color: #A92D62;

}

.faqans{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;
/* or 147% */

letter-spacing: -0.02em;

color: #646464;

}

.-right-10 {
  right: -3.5rem;
}

.contactheading{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 200% */

letter-spacing: -0.015em;

color: #01BABF;

}

.contactbtn{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  
  border-radius: 10px;
  align-items: center;
  
  color: #FFFFFF;

}


.contactsales{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 30px;
  /* identical to box height, or 214% */
  height: 67px;
  width: 301px;


  
background: #A92D62;
border-radius: 10px;
border-radius: 10px;

  text-align: center;
  letter-spacing: -0.02em;
  
  color: #FFFFFF;
}
.contactsales:hover > a{
  color: #FFFFFF !important;

  text-decoration: none !important;
}

.linkk {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: -0.015em;
  /* margin-right: 8.8rem!important; */
  /* margin-left: 0.8rem!important; */
  color: #048A8F;
  /* text-align: end; */
  padding: 0px 14px 0px 132px !important;
}



.homechaticon{

  /* width: 58px; */
  margin-top: -103px;
  border-radius: 0px;

}

.Translation{
  margin-top: -60px;
}
.grid-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 2px;



}
.lang-outline{
  border: #BED7D2 !important;
  outline: 2px solid #BED7D2;
  border-radius: 30px 30px 30px 30px;
}
/* .homelogo{
 
  margin: 1px 2px 2px 48px;
} */
.videodots{
  left: 574px;
    /* top: 744px; */
    margin-top: -133px;
 
}

a:hover {
 
  color: #A92D62 !important;
  text-decoration: wavy !important;
}


/*footer*/
.main-footer {
  color: white;
  background-color: #dc3545;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
}


.VideoPlayer{
 
  height: 445.589844px;
  width: 575.191772px;
  border-radius: 20px;

}

/* .downloadapp{
 
 font-size: 17px !important;
 font-weight: 500 !important;
            
} */
.video-js .vjs-tech{
  position: absolute;
  top: 0;
  /* left: 8px; */
  width: 100%;
  height: 100%;
  /* right: 0px; */
  padding: 0px 57px 0px 57px;

}
.video-js .vjs-big-play-button {

  /* line-height: 1.5em; */
  /* height: 1.5em; */
  /* width: 2em; */
  /* display: block; */
  position: absolute;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #EEF9FA;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
  /* height: 1.75rem; */
  border-radius: 50%;
  width: 46px;
  height: 46px;
  /* padding: 0px 2px 2px 14px; */
  background: #fff;
  /* border: 3px solid #000; */
  color: #43A7AB;
  text-align: center;
  /* font: 21px Arial, sans-serif; */
  /* margin-right: 32px; */
  left: 50%;
}

.video-js {
  display: block;

  box-sizing: border-box;
  color: #fff;
  background-color: #EEF9FA;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  border-radius: 15px;
  font-weight: normal;
  font-style: normal;
  font-family: 'Raleway';
  word-break: initial;
}
.inset-y-0 {
  top: 228px;
  bottom: 228px;
}



.FFpbKc {
  align-items: center;
  display: flex;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 4px;
}
.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #00ac9d;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }










/*home media*/







/*Tablets  desktop*/
@media (min-width: 1080px) and (max-width: 1600px) {
  
  .linkk{
    font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: -0.015em;
  /* margin-right: 8.8rem!important; */
  /* margin-left: 0.8rem!important; */
  color: #048A8F;
  /* text-align: end; */
  padding: 0px 14px 0px 499px;
  
}
}
/*bigger screens*/
@media (min-width: 1601px) and (max-width: 2000px) {
  
  .linkk{
    font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: -0.015em;
  /* margin-right: 8.8rem!important; */
  /* margin-left: 0.8rem!important; */
  color: #048A8F;
  /* text-align: end; */
  padding: 0px 14px 0px 499px !important;
  
}
}

/*mobile*/
@media (min-width: 320px) and (max-width: 480px) {
  
  .home {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #048A8F;
    /* margin-top: 40px; */
    margin-top: -32px !important;
  }
  
::placeholder {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  

  
  color: #202020;
}
  .subtitile{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
/* or 171% */

text-align: center;

color: #323232;
  }

  .fbbutton{
    font-family: 'Raleway' !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    height: 42px !important;
    width: 97px !important;
    border-radius: 5px !important;
  
  }
.follow{
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 24px;
/* identical to box height, or 240% */
color: #048A8F;

align-items: center;
letter-spacing: -0.015em;

}

.reserv{
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 24px;
/* identical to box height, or 240% */
margin: 0px -62px 2px 0px;

align-items: center;
letter-spacing: -0.015em;
}

.lang9{
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-top: -9px !important;


}

.top-60 {
  top: 10rem;
}

.right-20 {
  right: 0rem;
}
.absolute {
  position: absolute;
}
.lang
{
  height: 25px;
  width: 35px;
  margin-top: 95px;
  margin-right: 10px;
}

.btnheadding {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;
  


  
}

.select {
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 12px;


display: flex;
align-items: center;
text-align: center;
height: 34px;
width: 90px;

border-radius: 15px;
color: #363636;


}

.mx-5{
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important
}
.btnsubheadding{

  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 12px;

align-items: center;
text-align: center !important;
height: 25px;
width: 105px;
left: 25px;
top: 226px;
border-radius: 15px;

}

.h-60  {
    height: 130px!important;
}
.translation {
 filter: drop-shadow(0px 2px 2px rgba(104, 127, 128, 0.25)); 
 box-shadow: 0px 4px 7px rgba(20, 66, 68, 0.15);
  border-radius: 0px 0px 0px 0px;
  width: 346px;
  margin-top: -30px;
}

.supportHeading{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  margin-top: -58px !important;
  color: #A92D62;

}
.supportDescription{
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 53px;
    margin-top: -25px;
    text-align: center;
    letter-spacing: -1px;
    margin-bottom: -22px;
    color: #048A8F;
}

.card1 {
  background: #FFFFFF;
  border: 1px solid rgba(4, 138, 143, 0.15);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  height: 54px !important;
  width: 98.6875px !important;
  padding: 19px !important;
  border-radius: 10px;
  margin: 0px 10px 1px 0px !important;
  padding: 3px 3px 3px 3px;

}
.translationcard{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 26px;
  
  color: #000000;
}
.subDescription{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 30px;
  /* or 375% */
  
  text-align: center;
  opacity: 2.1;
  color: #676767;
}
.chaticon{
  height: 60px;
width: 58px;
margin-top: -110px;
border-radius: 0px;

}

.docicon{
  height: 29px;
width: 36px;

border-radius: 5px;

}
.homechaticon{
  height: 60px;
width: 58px;
margin-top: -32px !important;
border-radius: 0px;

}

.-mt-20 {
  margin-top: -8rem;
}

.aboutHeading{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 25px;
 
  
  
  color: #A92D62;
}

.subHeading1{
  
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 20px;

padding-right: 5px;

color: #048A8F;

}
.subHeading2{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 8px;
line-height: 20px;
/* or 250% */
padding-top: 1px;
padding-right: 7px;

color: #363636;
}
.faq{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 25px;

  margin-top: -36px;
  color: #A92D62;
}
.faqHeadding{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */


color: #048A8F;
}

.faqquestions{
 
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 8px;
margin: -59px -6px 0px 21px;
line-height: 10px !important;
letter-spacing: -0.02em;
color: #A92D62;
padding: 29px 0px 0px 0px;

}
.faqans{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* or 150% */
  
  letter-spacing: -0.02em;
  
  color: #646464;
}
.contactsales{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */
  height: 46px;
width: 230px;

border-radius: 10px;

  text-align: center;
  letter-spacing: -0.02em;
  
  color: #FFFFFF;
}

.contactsales:hover > a{
  color: #FFFFFF !important;
text-decoration: none !important;
}

.w-\[416px\] {
  width: 180px;
  

border-radius: 10px;
}
.h-\[485px\] {


  height: 422px;



border-radius: 10px;

}


.pricingbtn{
  height: 35px !important;
  width: 109px !important;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 26px;
  text-align: center;
  /* color: #FFFFFF; */
  border-radius: 10px;
  
}
.pricinglabel{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 26px;
/* identical to box height, or 217% */


color: #048A8F;
}
.dollar{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */


color: #01BABF;
}
.pricingper{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 26px;
/* identical to box height, or 217% */


color: #000000;
}

  .wordscontent{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 28px;
/* identical to box height, or 233% */


color: #000000;
  }
  .pricingHeading{
    font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 80px;
/* or 571% */
margin-top: -73px !important;
text-align: center;

color: #363636;

  }

  .pricingtitle{
    top: 79px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 80px;
    text-align: center;
    margin: -71px 0px 0px 0px;


  }
  .contactusbtn {
    font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
/* or 171% */

text-align: center;


  }
  .contacttitle{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */
    margin-top: -48px !important;
    text-align: center;
    
    color: #A92D62;
  }

  .contactHeading{
    font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 80px;
/* or 571% */
margin-top: -37px !important;
text-align: center;

color: #363636;
  }
.contactusform{
  margin-top: -14rem !important;
}
  .rightCol {
    flex: 0 0 16%;
 
    display: flex;
   
  }
  .leftCol {
    flex: 0 0 84%;

    display: flex;
  
  }

  /* iframe {
    background: '#C2FCFE';
    border: 13px solid #EEF9FA;
    -moz-border-radius: 15px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    height: 294px !important;
    width: 100%;
} */

.random {
  width: 1.75rem;
  height: 1.75rem;
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;


letter-spacing: -0.02em;

color: #FFFFFF;
}

.footerHeading{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 24px;
/* or 240% */

letter-spacing: -0.015em;


}
.contacttext{
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 24px;
/* identical to box height, or 240% */

letter-spacing: -0.015em;


}
.contactheading{
  font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */

letter-spacing: -0.015em;

color: #01BABF;

}


.contactlabel {
  margin-top: 1.5rem!important;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 165%;
  display: flex;
  align-items: center;
  color: #A92D62;
}
.contactbtn{
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    height: 46px;
    width: 212px;
    /* left: 35px; */
    /* top: 420px; */
    border-radius: 10px;
    margin-top: -14px;

    border: 0px solid;


}



.fbgoogle{
  width: 157px;
height: 48px;

}
.contactdots{
  height: 31.847599029541016px;
  width: 106px;
  border-radius: 0px;
  margin-top: 18px;
  /* text-align: center !important; */
  margin-left: 14%;


}
 .dark {
  outline: 0px;
  border-radius: 25px 25px 25px 25px;
} 
.heroimg{
  margin: 72px 3px 0px 0px;
}
.mask2 {

  
  position: absolute;
    z-index: -8;
    margin-right: 101px !important;
    margin-top: 2px;
    width: 208px;
    margin-left: -15px;
}
.faqdots{
  margin-top: -62px;
  margin-right: 0px;
}
.formmask {
  margin: 4px -67px -338px -8px;
  height: 289px !important;
}

textarea{
  font-size: 12px;
  padding: 0.5rem;
}
.input {
  height: 30px !important;


border-radius: 3px;

font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 8px;
line-height: 165%;
/* or 13px */


align-items: center;



}
.forgetforms{
  margin-top: -475px;
}
.dots2 {
  /* margin-left: 1372px; */
  margin-top: -40px;
  /* margin-bottom: -24px;F */
}
.forgotdots {
  /* margin-left: 1372px; */
  margin-top: 33px;
  /* margin-bottom: -24px; */
}
.logindots{
  margin-top: -47px;
    margin-right: -35px;
}
.backSignin{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
text-align: center;
color: #01BABF;
margin: 0px 116px 0px 116px;
}
.forms{
  margin-top: -332px;
}
.ml-0 {
  margin-left: 0px !important;
    margin-top: 41px;
}

.screen{
  
}
#togglePassword{
  margin-left: -90%;
  margin-top: 0%;
}



.pricingdec {



  margin-top: -75px !important;
  height: 266px;
 
}
.texttranslation{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
/* or 214% */

align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #363636;

}
.parameters{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 30px;
/* or 214% */

padding: 2px 13px 0px 13px;
align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #646464;

}
.pricepermonth{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
/* identical to box height, or 188% */


color: #048A8F;
}

.pricepermonthdec{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 13.5px;
line-height: 30px;
/* or 222% */

display: flex;
align-items: flex-end;
text-align: justify;
letter-spacing: -0.015em;

color: #363636;

}

.newuser{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 35px;
/* or 233% */

padding: 0px 34px 0px 34px;
align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #363636;
}
.guest{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

align-items: center;
letter-spacing: -0.015em;

color: #363636;
}
.gusetdes{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
/* or 214% */


align-items: center;
letter-spacing: -0.015em;

color: #363636;
}

.flexitemhead{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
letter-spacing: -0.015em;
height: 36px;

left: 0px;
top: 1903px;
border-radius: 0px;

color: #363636;

}


.flexitemDes{
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
letter-spacing: -0.015em;

color: #363636;
}
.contactcus{
  
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 25px;
/* identical to box height, or 156% */

text-align: center;

color: #A92D62;
}

.getintouch{
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 30px;
/* identical to box height, or 167% */

text-align: center;

color: #363636;
}


.contactcusdec{
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
/* or 214% */


align-items: center;
text-align: center;
letter-spacing: -0.015em;

color: #363636;
}




.contactusbg {
  background: #f7f7f7;
  height: 299px;
}

 
.downloadapp{
  width: 150px !important;
 height: 38px !important;
 margin-top: 8px;
 font-size: 14px !important;
            
}
.en {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  letter-spacing: -0.015em;
  margin-right: 1rem!important;
  margin-left: 5%!important;
  color: #048A8F;
  margin-top: 4px;
}

.translationdots{
  margin-top: 370px;
}

.clear {
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;

align-items: center;
text-decoration-line: underline;

color: #A92D62;
}
.controls3 {
  text-align: left;
  margin-top: -28px;
  margin-left: 162px;
  border-radius: 25px 25px 25px 25px;
  border: 0px;
}
.getquote{
 
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  height: 46px;
  width: 165px;
  margin-top: -10px;
  color: white;
  background: #A92D62;
  font-family: 'Raleway';
  border-radius: 10px;
 
}
.VideoPlayer{
  width: 156px !important;
    height: 181px !important;
    left: 14px;


}
.video-js {
  display: block;

  box-sizing: border-box;
  color: #fff;
  background-color: #EEF9FA;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  border-radius: 25px;
  font-weight: normal;
  font-style: normal;
  font-family: 'Raleway';
  word-break: initial;
}

.video-js .vjs-big-play-button {

    /* line-height: 1.5em; */
    /* height: 1.5em; */
    /* width: 2em; */
    /* display: block; */
    position: absolute;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 0.3em;
    transition: all 0.4s;
    /* height: 1.75rem; */
    border-radius: 50%;
    width: 46px;
    height: 46px;
    /* padding: 0px 2px 2px 14px; */
    background: #fff;
    /* border: 3px solid #000; */
    color: #43A7AB;
    text-align: center;
    /* font: 21px Arial, sans-serif; */
    /* margin-right: 32px; */
    left: 66% !important;
}
.video-js .vjs-tech{
  position: absolute;
    top: 0;
    /* left: 8px; */
    width: 100%;
    height: 100%;
    /* right: 0px; */
    padding: 0px 7px 0px 7px !important;
   
}
.footerlogo{
  margin: 1px 2px 2px 33px;
  height: 65px;
}
/* .homelogo{
  height: 65px;
  margin: 1px 2px 2px 33px;
} */
/*NAVBAR*/
 .navbar-light .navbar-toggler-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABFSURBVHgB7ZGxDQAgCATfTdzM1XQycBLFloSYp+aSo+DLawCG2cExze2fYh7SgYKmIVfNo+9kqnkFxRe2mporGplqYZ0LpCUv91hgBTIAAAAASUVORK5CYII=);
    text-align: left;
    height: 13.5px;
    width: 18px;
    left: 3px;

    border-radius: 0px;
    margin-top: -29px;

} 
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-light .navbar-toggler {
  border-color: rgba(252, 251, 251, 0.1);
    margin-left: 8px;
    margin-right: 80px;
    color: rgba(0,0,0,.55);
    margin-top: -50px;
}

.navbar-brand {
  padding-top: 0.3125rem; 
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  margin-left: 100px;
}

.navbar-brand {
  margin-left: 50px;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-right: 0rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  width: 198px !important;
  height: 357px;
  
  border-radius: 0px 20px 20px 0px;
  box-shadow: 5px 10px 5px 5px #d9d9d9;
}

.linkk {
  font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-right: 0.8rem!important;
    margin-left: -7.2rem!important;
    color: #048A8F;
    margin-top: 40px;
}

.link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin-right: 0.8rem!important;
  margin-left: 0.8rem!important;
  color: #048A8F;
}
.link-active {
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-right: 0.8rem!important;
    margin-left: 0.8rem!important;
    color: #01BABF;
}

.homelogo{
  height: 75px;
}

}

 @media (min-width: 481px) and (max-width: 768px) {

 
} 

@media (min-width: 768px)
{
.navbar-expand-md .navbar-nav {
    flex-direction: row ; 
}

.navbar-expand-md .navbar-collapse {
   display: flex!important;
    flex-basis: auto;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}


.navbar-expand-md .navbar-toggler {
   display: none;
}
} 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

  .navbar-expand-md .navbar-nav {
    flex-direction: row ; 
}

.navbar-expand-md .navbar-collapse {
   display: flex!important;
    flex-basis: auto;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}


.navbar-expand-md .navbar-toggler {
   display: none;
}

}

/* .text-base{
  margin-top: -232px !important;
} */

/* @media (min-width: 991px) {

  .navbar-expand-md .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none !important;
}
.navbar-expand-md {
  flex-wrap: nowrap !important;
  justify-content: flex-start;
} 
}*/


.ellipsis  {

  display: -webkit-box;
  max-width: 367px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.readmore {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 40px;
  align-items: center;
  color: #000000;
  overflow-y: scroll;
}

.modal-body{
  height: 250px;
  overflow-y: auto;
}

.csvexport:hover{
color: white !important;
}




.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 8px;
}

.popupfiles{
margin-top: 116px;
}