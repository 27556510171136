@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.image-tanslation {
	margin-top: vars.$spacing-xx-large;
	margin-bottom: vars.$spacing-xx-large;

	&__title {
		@include mix.text(
			vars.$text-family-fira,
			vars.$color-light-black,
			vars.$text-size-large-x,
			500,
			vars.$spacing-atomic-xx
		);
	}

	&__description {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-micro-x,
			vars.$text-weight-light,
			vars.$spacing-atomic-xx
		);
	}

	&__file {
	}
}
