.py-8 {
  padding-bottom: 0px;
  padding-top: 100px !important;
}

.dotss {
  margin-left: 1272px;
  margin-top: 0px;
}
.solutiontext {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 50px;
  /* or 227% */

  color: #363636;
}
.knowmore {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */

  color: #a92d62;
}
.choose {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */

  text-align: center;

  color: #a92d62;
}
.card2 {
  margin: 0.5rem;
  display: flex;

/*   cursor: pointer; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
  padding: 0.75rem;

  filter: drop-shadow(8px 8px 4px #e8effe);

  width: 400px;
  height: 178px;

  /* Color 1 */

  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.cardheadding {
  text-align: center !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 894px;
    margin: 10.75rem auto;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* pointer-events: auto; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1pxsolidrgba (0, 0, 0, 0.2);
  border-radius: 0.3rem;

  background: #ffffff;
  border-radius: 40px;
}

.btn-close {
  box-sizing: content-box;
  width: 1em !important;
  height: 1em !important;
  padding: 0.25em 0.25em !important;
  color: #000 !important;
  background: url(../../../public/assets/imgs/close.png) center/1em auto no-repeat !important;
  border: 0;
  border-radius: 0.25rem !important;
}

#button {
  text-align: left;
  background: none;
}

@media (min-width: 992px) {
  /* .col-lg-4 {
    flex: 0 0 auto;
    width: 37.333333%;
}
.col-lg-3 {
    flex: 0 0 auto;
    width: 16%;
}
 */
}

.cardtitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #048a8f;
}

.cardtext {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  text-align: center;

  color: #000000;
}
.top-20 {
  top: 9rem !important;
}
.modal-header .btn-close {
  padding: 1.5rem 0.5rem;
  margin: -11.5rem 0.5rem -0.5rem auto !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .solutiontitle {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 25px !important;
    text-align: center !important;
    color: #a92d62 !important;
    top: 115px !important;
    margin-top: 20px !important;
  }
  .modal-header .btn-close {
    padding: 1.5rem 0.5rem !important;
    margin: -1.5rem 0.5rem -0.5rem auto !important;
}
  .top-20 {
    top: 4rem !important;
  }
  .-left-30{
    left: -96px;
  }
  .top-80 {
    top: 20rem !important;
  }
  .textrow {
    margin-top: -65px !important;
}
.logomodal{
  height: 46px;
width: 129px;

border-radius: 0px;

}
.txtmodal{
  height: 24px;
width: 22px;

border-radius: 0px;

}

.pp{

}
  .langIcon {
    width: 54px !important;
    height: 30.91px !important;
    top: 90px !important;
    transform: matrix(1, 0, 0, 1, 0, 0) !important;
  }
  .txtImage {
    position: absolute !important;
    left: 10% !important;
    right: 10% !important;
    top: 0% !important;
    bottom: 0% !important;
  }
  .solutioncardHeadding {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 60px !important;
    margin-top: -46px !important;
   
  }
.ppaudio{

  color: #048A8F;
  
  margin-top: -90px !important;
  text-align: center !important;

  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 10px !important;
line-height: 30px !important;

margin: 0px -13px 0px 13px;


}


  .solutiontext {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 24px !important;
    margin-top: -10px !important;
  }
  .knowmore {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 25px !important;
    margin-top: 1px !important;
  }
  .choose {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 25px !important;
    margin-top: -35px !important;
  }
  .solutionHeadding {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 80px !important;
    margin-top: -48px !important;
  }

  .bringingSolutions {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 80px !important;
    margin-top: -90px !important;
  }
  .cardtitle {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .cardtext{
    font-weight: 400 !important;
    font-size: 8px !important;
    line-height: 18px !important;

  }

  .pp {
    margin: -25px 0px -79px 29px !important;
    height: 73px;
  }

  .card2 {
    margin: 0.5rem !important;;
    display: flex !important;;
   /*  cursor: pointer; */
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.375rem !important;;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity));
    padding: 0px !important; 
    filter: drop-shadow(8px 8px 4px #e8effe);
  
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    width: 156px !important;;
    height: 82px !important;;
  }
  .modaltitle{

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    
    color: #048A8F;

}


.modaltext{
  font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 28px !important;
    align-items: center !important;
    color: #000000 !important;
    /* margin-top: -29px; */
    margin: 0px 0px 0px 0px;
    /* height: 82px; */

}


}
.grid-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 2px;



}

.cardpp {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  background-clip: border-box;
  border: 0px solid rgba(0,0,0,.125) !important;
  border-radius: 0.25rem;
}

.pp {
  text-align: center !important;

  margin: 30px 0px 0px 148px;
}