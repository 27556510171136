@mixin button(
	$width,
	$padding,
	$bg-color,
	$hover-bg-color,
	$fg-color,
	$hover-fg-color,
	$b-radius,
	$font-size,
	$font-weight
) {
	border: none;
	padding: $padding;
	width: $width;
	background-color: $bg-color;
	color: $fg-color;
	border-radius: $b-radius;
	font-size: $font-size;
	font-weight: $font-weight;
	text-decoration: none;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ease-in-out 150ms;

	&:hover {
		background-color: $hover-bg-color;
		color: $hover-fg-color;
	}
}

@mixin text($family, $color, $size, $weight, $lineHeight) {
	font-size: $size;
	font-weight: $weight;
	font-family: $family;
	color: $color;
	line-height: $lineHeight;
}

@mixin area($display, $position, $width, $height, $padding, $margin, $backgroundColor) {
	display: $display;
	position: $position;
	width: $width;
	height: $height;
	padding: $padding;
	margin: $margin;
	background-color: $backgroundColor;
}

@mixin borderedArea($borderThickness, $borderColor, $borderRadius) {
	border: $borderThickness solid $borderColor;
	border-radius: $borderRadius;
}

@mixin flexArea($direction, $alignItems, $justifyContent) {
	display: flex;
	flex-direction: $direction;
	align-items: $alignItems;
	justify-content: $justifyContent;
}

@mixin divider($height: 1px, $color, $width) {
	border-top: $height solid $color;
	width: $width;
}
