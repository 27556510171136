.desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  
  .headding{
    
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */
  
  color: #170F49;
  /* Neutral/800 */
  
  
  }
  
  
  .pricing-para{
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    /* identical to box height, or 135% */
    
    
    /* Neutral/800 */
    
    color: #170F49;
  }
  
  .pricing-summary{
  
  
  
  
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  
  
  color: #6F6C90;
  }
  
  .pricing-head{
    
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height, or 146% */
  
  
  color: #087F84 !important;
  }
  
  
  
  .pricing-subhead{
    
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */
  
  
  color: #646A89;
  
  }
  
  .my-0{
  
    
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  
  
  color: #170F49;
  }
  
  
  
  .rounded-pill{
  background: #FFFFFF;
  
  
  border: 1px solid #EFF0F7;
  
  height: 55px;
  width: 284px;
  left: 510px;
  top: 1788.62744140625px;
  
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
 }
  
  
  .rounded-pillAddress{
    background: #FFFFFF;
  border: 1px solid #EFF0F7;
  height: 55px;
  width: 352px;
  left: 198px;
  top: 2084px;
  border-radius: 46px;
  
  
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;
  }
  
  
  .rounded-pillzipcode{
    background: #FFFFFF;
  border: 1px solid #EFF0F7;
  height: 55px;
  width: 141px;
  left: 510px;
  top: 1936px;
  border-radius: 46px;
  
  
  
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;
  }
  
  .pricing-footertext{
    
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  /* or 143% */
  
  
  color: #353950;
  }
  
  .rounded-pillpayment{
    background: #01BABF  !important;
    box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18) !important;
    color:#000 !important;
    border-radius: 56px;
    height: 61px;
  left: 786px;
  top: 2333px;
  border-radius: 56px;
  padding: 20px 40px 21px 40px;
  text-align: center;
  
  
  
  color: #FFFFFF;
  
  
  
  
  flex: none;
  order: 0;
  flex-grow: 0;
  
  }
  .rounded-pillpaymentCancel{
    background: #01BABF  !important;
    box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18) !important;
    color:#000 !important;
    left: 786px;
    top: 2333px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  
  
  
  .profile-card {
   
  
   
  
    &__img {
   
      
     text-align: center ;
     margin-top: 64px;
  
    
  
    }
  
    &__name {
      
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      /* identical to box height, or 146% */
      
      text-align: center;
      
      /* Neutral/800 */
      
      color: #170F49;
    }
  
    &__txt {
      
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */
      
      text-align: center;
      
      /* Neutral/600 */
      
      color: #6F6C90;
      
  
      strong {
        //color: #ff2846;
        font-weight: 700;
      }
  
    }
  
  
  
    &-inf {
      
  
    
  
      &__title {
        font-weight: 700;
        font-size: 27px;
        //color: #6944ff;
        color: #324e63;
      }
  
      &__txt {
        font-weight: 500;
        margin-top: 7px;
      }
  
    }
  
    
  
    &-ctr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
  
      @media screen and (max-width: 576px) {
        flex-wrap: wrap;
      }
  
    }
  
    &__button {
      background: none;
      border: none;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 19px;
      margin: 15px 35px;
      padding: 15px 40px;
      min-width: 201px;
      min-height: 55px;
      color: #fff;
      cursor: pointer;
      backface-visibility: hidden;
      transition: all .3s;
  
      @media screen and (max-width: 768px) {
        min-width: 170px;
        margin: 15px 25px;
      }
  
      @media screen and (max-width: 576px) {
        min-width: inherit;
        margin: 0;
        margin-bottom: 16px;
        width: 100%;
        max-width: 300px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
    
  
      @media screen and (min-width: 768px) {
        &:hover {
          transform: translateY(-5px);
        }
      }
  
  
     
  
      &.button--blue {
        background: linear-gradient(45deg, #01BABF, #01BABF);
        box-shadow: 0px 4px 30px rgba(75, 199, 215, 0.4);
  
        &:hover {
          box-shadow: 0px 7px 30px rgba(75, 199, 215, 0.4);
        }
  
      }
  
   
  
  
    }
  
  
    
  
  
  
  }
  .feedback {
    color: red;
  }
  
  
  
  
  
  
  
  