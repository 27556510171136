@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.userplans {
	@include mix.flexArea(column, center, center);
	margin-left: vars.$spacing-micro;
	margin-right: vars.$spacing-micro;
	margin-top: vars.$spacing-medium;
	margin-bottom: vars.$spacing-large-increased;

	&__title {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-large-xx,
			vars.$text-weight-bold,
			initial
		);
	}

	&__table__action {
		margin: 0 vars.$spacing-atomic;
		cursor: pointer;
		outline: none;
		border: none;
	}
	&__table__action:hover {
		color: vars.$color-secondary;
	}
}
