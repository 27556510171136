@use '../../styles/variables' as vars;
@use '../../styles/mixins' as mix;

.document-tanslation {
	margin-top: vars.$spacing-xx-large;
	margin-bottom: vars.$spacing-xx-large;

	&__title {
		@include mix.text(
			vars.$text-family-fira,
			vars.$color-light-black,
			vars.$text-size-large-x,
			500,
			vars.$spacing-atomic-xx
		);
	}

	&__description {
		@include mix.text(
			vars.$text-family-urw,
			vars.$color-light-black,
			vars.$text-size-micro-x,
			vars.$text-weight-light,
			vars.$spacing-atomic-xx
		);
	}

	&__form {
		@include mix.flexArea(column, center, center);
		margin-bottom: vars.$spacing-micro;
		width: 100%;

		.document-tanslation__browse {
			display: inline-block;
			cursor: pointer;
			@include mix.button(
				25%,
				vars.$spacing-xx-atomic,
				vars.$color-secondary,
				vars.$color-secondary-light,
				vars.$color-white,
				vars.$color-white,
				vars.$spacing-xx-atomic,
				vars.$text-size-micro,
				vars.$text-weight-light
			);
		}

		.document-tanslation__lang {
			@include mix.flexArea(row, center, space-between);
			width: 50%;
			margin-top: vars.$spacing-atomic-xx;
			margin-bottom: vars.$spacing-atomic-xx;

			.document-tanslation__select {
				// outline: 1px solid vars.$color-secondary;
			}
		}

		.document-tanslation__submit {
			@include mix.button(
				vars.$spacing-large-increased,
				vars.$spacing-atomic,
				vars.$color-primary,
				vars.$color-primary-light,
				vars.$color-white,
				vars.$color-white,
				vars.$spacing-atomic,
				vars.$text-size-micro,
				vars.$text-weight-light
			);
			margin: vars.$spacing-xxx-micro;
		}

		.document-tanslation__download {
			@include mix.button(
				vars.$spacing-large-increased,
				vars.$spacing-atomic,
				vars.$color-input-fg-2,
				vars.$color-input-fg,
				vars.$color-white,
				vars.$color-white,
				vars.$spacing-atomic,
				vars.$text-size-micro,
				vars.$text-weight-light
			);
			margin: vars.$spacing-xxx-micro;
		}

		.document-tanslation__waiting {
			@include mix.flexArea(column, center, center);
			@include mix.text(
				vars.$text-family-urw,
				vars.$color-light-black,
				vars.$text-size-micro-x,
				vars.$text-weight-light,
				vars.$spacing-atomic-xx
			);
			margin: vars.$spacing-atomic-xx;
		}
	}
}
