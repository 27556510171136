* {
	font-family: 'Raleway';
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: unset;
}

a:hover {
	color: unset;
	text-decoration: none;
}

p {
	overflow-wrap: break-word;
	word-wrap: break-word;
}
