.image-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: initial;
}
.rememberMe {
  font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;

color: #6F6F6F;
}
.gradient-login-btn {
  padding-left: 4rem;
  padding-right: 4rem;
}

.signbtn {
  border-radius: 15px;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #a92d62;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(169 45 98 / 0));
  --tw-gradient-to: #a92d62;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 20px;
  line-height: 23px;
  font-family: Raleway;
}

.sinnewuser {
  margin-left: 30.75rem;
  margin-top: 30px;
}

.ml-4 {
  margin-left: -8rem;
}


.text-md {
  left: 55.78%;
  right: 39.74%;
  top: 48.22%;
  bottom: 49.58%;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;

  color: #048a8f;
}

.singin {
  height: 517px;
  width: 578px;

  border-radius: 0px;
  margin-right: 30px;
  margin-left: -152px;
  margin-top: 51px;
}

.screen {
  margin-left: -11px;
}
.main {
  display: flex;
  justify-content: space-between;
}
.dots {
  /*  margin-left: 1372px; */
  margin-top: -77px;
}

.dots2 {
  /*  margin-left: 1372px; */
  margin-top: -94px;
}
.dots1 {
  /*  margin-left: 1372px; */
  margin-top: -55px;
}
.ml-0 {
  margin-left: -100px !important;
  margin-top: 51px;
}

.banner {
  height: 1120.3923559981545px;
  width: 667.8006112656627px;
  left: 182.34765625px;
  top: -338px;
  border-radius: 50px;
}
.label {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: #01babf;
}

.inputtext {
  width: 100%;
  border-radius: 15px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  padding: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 14px;
  --tw-text-opacity: 1;
  color: rgb(111 111 111 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: 1px solid #ebebeb;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #6f6f6f;
}

#fbgoogle {
  filter: drop-shadow(0px 4px 4px rgba(1, 186, 191, 0.25));
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(1 186 191 / 25%);
  font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #323232;
}

.iaccept{
  font-family: 'Raleway' !important;

font-weight: 600 !important;
font-size: 15px !important;
line-height: 14px !important;
text-align: center !important;

color: #6F6F6F !important;
}

@media (min-width: 320px) and (max-width: 480px)  {
  .title {
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 35px !important;
    width: 375px !important;
    height: 28px !important;
    text-align: center !important;

    right: 0px !important;
    top: 222px !important;
  }
  .emailLabel {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .input1 {
    width: 335px !important;
    height: 48px !important;
    right: 20px !important;
    top: 450px !important;
    background: #ffffff !important;
    border: 1px solid #ebebeb !important;
    border-radius: 5px !important;
  }
  .checkBoxR {
    width: 17.94px !important;
    height: 18.82px !important;
    right: 337.06px !important;
    top: 609px !important;
  }
  .rememberMe {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    width: 196px !important;
    height: 16px !important;
    right: 243px !important;
    top: 610px !important;
    color:  #6F6F6F !important;
  }
  .gradient-btn {
    width: 335px !important;
    border-radius: 5px !important;
    height: 48px !important;
    right: 20px !important;
    top: 668px !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }
  .newAccount {

  
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    justify-content: center !important;
  }
  .forgotPass {
    width: 107px !important;
    height: 13px !important;
    right: 20px !important;
    top: 611px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .alreadyMember{
    font-family: 'Raleway' !important;

font-weight: 600 !important;
font-size: 12px !important;
line-height: 14px !important;
text-align: center !important;

color: #323232 !important;
  }
  


  .iaccept{
    font-family: 'Raleway' !important;

font-weight: 600 !important;
font-size: 12px !important;
line-height: 14px !important;
text-align: center !important;

color: #6F6F6F !important;
  }
  .text-sm {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    line-height: 16px !important;
    /* text-align: left; */
}
  .signIn{
    font-family: 'Raleway' !important;

    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    
    color: #01BABF !important;
  }

  .createUser{
    font-family: 'Raleway' !important;

    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    
    color: #01BABF !important;
}

.newUser{
  font-family: 'Raleway' !important;

font-weight: 600 !important;
font-size: 12px !important;
line-height: 14px !important;
text-align: center !important;

color: #323232 !important;
}
}
@media (min-width: 320px) and (max-width: 568px)  {
  .title {
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 35px !important;
    width: 375px !important;
    height: 28px !important;
    text-align: center !important;

    right: 0px !important;
    top: 222px !important;
  }
  .emailLabel {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .input1 {
    width: 335px !important;
    height: 48px !important;
    right: 20px !important;
    top: 450px !important;
    background: #ffffff !important;
    border: 1px solid #ebebeb !important;
    border-radius: 5px !important;
  }
  .checkBoxR {
    width: 17.94px !important;
    height: 18.82px !important;
    right: 337.06px !important;
    top: 609px !important;
  }
  .rememberMe {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    width: 196px !important;
    height: 16px !important;
    right: 243px !important;
    top: 610px !important;
    color:  #6F6F6F !important;
  }
  .gradient-btn {
    width: 335px !important;
    border-radius: 5px !important;
    height: 48px !important;
    right: 20px !important;
    top: 668px !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }
  .newAccount {

  
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    justify-content: center !important;
  }
  .forgotPass {
    width: 107px !important;
    height: 13px !important;
    right: 20px !important;
    top: 611px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .alreadyMember{
    font-family: 'Raleway' !important;

font-weight: 600 !important;
font-size: 12px !important;
line-height: 14px !important;
text-align: center !important;

color: #323232 !important;
  }
  
  .text-sm {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    line-height: 16px !important;
    /* text-align: left; */
}
  .signIn{
    font-family: 'Raleway' !important;

    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    
    color: #01BABF !important;
  }

  .createUser{
    font-family: 'Raleway' !important;

    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    
    color: #01BABF !important;
}

.newUser{
  font-family: 'Raleway' !important;

font-weight: 600 !important;
font-size: 12px !important;
line-height: 14px !important;
text-align: center !important;

color: #323232 !important;
}
}
